<template>
  <div class="product-attributes">
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      <div class="flex-shrink-0">
        Атрибуты продуктов ({{ filters.total }})
        <i v-if="loading" class="el-icon-loading"></i>
      </div>
      <div class="flex items-center w-full justify-end">
        <el-button
          size="medium"
          plain
          type="primary"
          icon="el-icon-plus"
          :disabled="loading"
          @click="editAttribute({ id: 'new' })"
          >Добавить</el-button
        >
      </div>
    </h1>

    <el-table :data="attributes" border size="small" @row-click="editAttribute">
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        label="N"
        align="center"
      ></el-table-column>
      <el-table-column prop="name" label="Название"></el-table-column>
      <el-table-column prop="type" label="Тип"></el-table-column>
      <el-table-column prop="unit" label="Единица измерения"></el-table-column>
    </el-table>

    <el-pagination
      class="text-center my-5"
      background
      :disabled="loading"
      layout="prev, pager, next"
      :total="filters.total"
      :page-size="filters.limit"
      :current-page.sync="filters.page"
      @current-change="() => handleFiltersChange('page')"
    >
    </el-pagination>

    <!-- Добавляем модальное окно -->
    <el-dialog
      :title="isNewAttribute ? 'Создание атрибута' : 'Редактирование атрибута'"
      :visible.sync="dialogVisible"
      width="500px"
      @close="handleDialogClose"
    >
      <el-form
        ref="attributeForm"
        :model="attributeForm"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item label="Название" prop="name">
          <el-input v-model="attributeForm.name"></el-input>
        </el-form-item>

        <el-form-item label="Тип" prop="type">
          <el-select v-model="attributeForm.type" style="width: 100%">
            <el-option label="Текст" value="string"></el-option>
            <el-option label="Число" value="number"></el-option>
            <el-option label="Булево" value="boolean"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Единица измерения" prop="unit">
          <el-input v-model="attributeForm.unit"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Отмена</el-button>
        <el-button type="primary" :loading="saveLoading" @click="saveAttribute"
          >Сохранить</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProductAttributes,
  createProductAttribute,
  updateProductAttribute,
} from "@/api/productAttributes";

export default {
  name: "ProductAttributes",
  data: () => ({
    attributes: [],
    loading: false,
    filters: {
      total: 0,
      pages_count: 0,
      page: 1,
      limit: 10,
      query: null,
    },
    dialogVisible: false,
    saveLoading: false,
    attributeForm: {
      name: "",
      type: "string",
      unit: "",
    },
    rules: {
      name: [
        {
          required: true,
          message: "Пожалуйста, введите название",
          trigger: "blur",
        },
        { min: 2, message: "Минимум 2 символа", trigger: "blur" },
      ],
      type: [
        {
          required: true,
          message: "Пожалуйста, выберите тип",
          trigger: "change",
        },
      ],
    },
  }),
  computed: {
    page() {
      return this.$route.query.page;
    },
    query() {
      return this.$route.query.query;
    },
    isNewAttribute() {
      return !this.attributeForm.id;
    },
  },
  async created() {
    if (this.page) {
      this.filters.page = parseInt(this.page, 10);
    }
    if (this.query) {
      this.filters.query = this.query;
    }
    await this.getAttributes();
  },
  methods: {
    async getAttributes() {
      this.loading = true;
      try {
        const result = await getProductAttributes(this.filters);
        const { attributes, pages_count, total } = result;
        this.attributes = attributes;
        this.filters.pages_count = pages_count;
        this.filters.total = total;
      } catch (error) {
        console.error("Ошибка при получении атрибутов:", error);
      } finally {
        this.loading = false;
      }
    },
    editAttribute(attribute) {
      if (attribute.id === "new") {
        this.attributeForm = {
          name: "",
          type: "string",
          required: false,
        };
      } else {
        this.attributeForm = { ...attribute };
      }
      this.dialogVisible = true;
    },
    handleDialogClose() {
      this.$refs.attributeForm?.resetFields();
      this.attributeForm = {
        name: "",
        type: "string",
        required: false,
      };
    },
    async saveAttribute() {
      try {
        await this.$refs.attributeForm.validate();

        this.saveLoading = true;
        if (this.isNewAttribute) {
          await createProductAttribute(this.attributeForm);
          this.$message.success("Атрибут успешно создан");
        } else {
          await updateProductAttribute(
            this.attributeForm.id,
            this.attributeForm
          );
          this.$message.success("Атрибут успешно обновлен");
        }

        this.dialogVisible = false;
        await this.getAttributes();
      } catch (error) {
        if (error.message) {
          this.$message.error(error.message);
        }
      } finally {
        this.saveLoading = false;
      }
    },
    changeUrlSearchParams() {
      this.$router.push({
        query: {
          page: this.filters.page,
          query: this.filters.query,
        },
      });
    },
    async handleFiltersChange(param) {
      if (param !== "page") {
        this.filters.page = 1;
      }
      this.changeUrlSearchParams();
      await this.getAttributes();
    },
    indexMethod(index) {
      return index + 1 + (this.filters.page - 1) * this.filters.limit;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-attributes {
  table {
    th {
      padding: 10px;
    }
    td {
      padding: 10px;
    }
  }
}
</style>
