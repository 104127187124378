import apiClient from "@/api/apiClient";

export const getProductAttributes = async (filters) => {
  const result = await apiClient.get("/api/product-attributes", {
    params: {
      ...filters,
    },
  });
  return result.data;
};

export const createProductAttribute = async (payload) => {
  const result = await apiClient.post("/api/product-attributes", payload);
  return result.data;
};

export const updateProductAttribute = async (id, payload) => {
  const result = await apiClient.put(`/api/product-attributes/${id}`, payload);
  return result.data;
};
